<template>

    <div class="layout-body">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    setup () {
    }
}
</script>
<style lang="scss" scoped>
.el-breadcrumb{
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
}
</style>